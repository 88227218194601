import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path : "/permohonan",
        name : "permohonan-list",
        component : () => import("@/views/Permohonan/Permohonan.vue"),
      },
      {
        path :"/permohonan/add",
        name:"permohonan-add",
        component:() => import("@/views/Permohonan/PermohonanAdd.vue")
      },
      {
        path :"/permohonan/edit/:id",
        name:"permohonan-edit",
        component:() => import("@/views/Permohonan/PermohonanEdit.vue")
      },
      {
        path :"/permohonan/view/:id",
        name:"permohonan-view",
        component:() => import("@/views/Permohonan/PermohonanView.vue")
      },
      {
        path :"/notification",
        name:"notification-sp3",
        component:() => import("@/views/kotak-pesan/Notification.vue")
      },
      {
        path :"/view-pesan/:id",
        name:"view-pesan",
        component:() => import("@/views/kotak-pesan/ViewReadPesan.vue")
      },
      {
        path :"/laporan",
        name:"laporan-permohonan",
        component:() => import("@/views/laporan/Laporan.vue")
      },
      {
        path:"/profile",
        name : "kt-account",
        component: () => import("@/views/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/account/Settings.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/authentication/MultiStepSignUp.vue"),
  },
  {
    path: "/please-wait",
    name: "please-wait",
    component: () =>
      import("@/views/authentication/waiting.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
