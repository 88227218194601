import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import axios from "axios";
import cookie from 'js-cookie'
config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
  },
  state: {
    bruhMoment: false
  },
  actions:{
    checkAuth(context){
      axios.get(process.env.VUE_APP_BASE_API+"check-auth",{
        params : {
          secret_code : cookie.get('token')
        }
      }).then((response)=>{
        cookie.set("access_token",response.data.token)
        context.state.bruhMoment = true
        cookie.remove('token')
      })
    },
  }
});

export default store;
